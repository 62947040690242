// Dashboard JS

// Select 2
// https://select2.org/getting-started/basic-usage

	jQuery(document).ready(function() {

		jQuery(".enhanced-select").select2();

	});

// Summernotes
// https://summernote.org/getting-started/

	jQuery(document).ready(function() {

		jQuery('.enhanced-textarea').summernote({
			tabsize: 2,
			height: 250
		});

	});

// Data tables
// https://datatables.net/reference/index 

	jQuery(document).ready(function() {

		jQuery('.enhanced-table').DataTable({
			"order": [], // stop default sorting
			'pageLength': 25,
			"dom":  "<'d-sm-flex justify-content-between align-items-center'<f><B>>" +
					"<'d-xl-flex justify-content-between align-items-center'<i><p>>" +
					"<tr>" +
					"<'d-xl-flex flex-xl-row-reverse justify-content-between align-items-center'<p><l>>",
			buttons: [
				{
					extend: 'csv',
					className: 'btn btn-outline-primary',
					text: '<i class="icon-download"></i> Export CSV',
					bom: true, // utf-8 encoding
					exportOptions: {
						stripHtml: true
					},
				},
				// {
					// extend: 'excel',
					// className: 'btn btn-outline-primary',
					// text: 'Export Excel',
					// bom: true,
					// exportOptions: {
						// stripHtml: true
					// },
				// }
			],
			initComplete: function() {

				var buttons = $('.dt-buttons button');
				buttons.removeClass('dt-button');

				this.api()
					.columns('.enhanced-table-column-filtrable')
					.every(function() {
						var column = this;
						var select = $('<select class="form-select"><option value=""></option></select>')
							.appendTo($(column.footer()).empty())
							.on('change', function() {
								var val = $.fn.dataTable.util.escapeRegex($(this).val());

								column.search(val ? '^' + val + '$' : '', true, false).draw();
							});

						column
							.data()
							.unique()
							.sort()
							.each(function(d, j) {
								var val = $('<div/>').html(d).text();
								select.append('<option value="' + val + '">' + val + '</option>'); // originally 'val' was 'd'
							});
					});
	
			},
			"columnDefs": [
				{
					"targets": "enhanced-table-column-not-sortable",
					"orderable": false,
				}, 
				{
					"targets": "enhanced-table-column-hidden-small-devices",
					"className": "d-none d-lg-table-cell d-xl-table-cell d-xxl-table-cell",
				}, 
				{
					"targets": "enhanced-table-column-wrap-text",
					"className": "text-wrap",
				}, 
				{
					"targets": "enhanced-table-column-width-25%",
					"width": "25%",
				}, 
			],
			language: {
				"processing": "Procesează...",
				"lengthMenu": "Afișează _MENU_ înregistrări pe pagină",
				"zeroRecords": "Nu am găsit nimic - ne pare rău",
				"info": "Afișate de la _START_ la _END_ din _TOTAL_ înregistrări",
				"infoEmpty": "Afișate de la 0 la 0 din 0 înregistrări",
				"infoFiltered": "(filtrate dintr-un total de _MAX_ înregistrări)",
				"search": "Caută:",
				"aria": {
					"sortAscending": "Sortează ascendent",
					"sortDescending": "Sortează descendent"
				},
				"autoFill": {
					"cancel": "Anulează",
					"fill": "Completează",
					"fillVertical": "Completează celule vertical",
					"fillHorizontal": "Completeză celule orizonal"
				},
				"buttons": {
					"csv": "CSV",
					"excel": "Excel",
					"pageLength": {
						"-1": "Arată toate rândurile",
						"_": "Arată %d rânduri"
					},
					"pdf": "PDF",
					"print": "Imprimă",
					"collection": "Colecție",
					"colvis": "Vizibilitate coloane",
					"copy": "Copie",
					"copyTitle": "Copie în Clipboard",
					"colvisRestore": "Resetare vizibilitate",
					"copyKeys": "Apasă ctrl sau u2318 + C pentru a copia datele din tabel în clipboard-ul sistemului.<br \/><br \/>Pentru a anula, dați clic pe acest mesaj sau apăsați pe Escape.",
					"createState": "Creează Stare",
					"removeAllStates": "Înlătură Toate Stările",
					"removeState": "Înlătură",
					"renameState": "Redenumește",
					"savedStates": "Salvează Stări"
				},
				"searchBuilder": {
					"add": "Adaugă",
					"value": "Valoare",
					"condition": "Condiție",
					"data": "Data",
					"logicAnd": "Și",
					"logicOr": "Sau",
					"button": {
						"0": "Generator de căutare",
						"_": "Generator de căutare (%d)"
					},
					"clearAll": "Curata tot",
					"conditions": {
						"date": {
							"after": "După",
							"before": "Inainte de",
							"between": "Între",
							"empty": "Gol"
						}
					},
					"deleteTitle": "Ștergeți regula de filtrare",
					"leftTitle": "Criterii Outdent",
					"rightTitle": "Criterii de indentare",
					"title": {
						"0": "Generator de căutare",
						"_": "Generator de căutare (%d)"
					}
				},
				"loadingRecords": "Încarcă...",
				"datetime": {
					"previous": "Precedentă",
					"next": "Următoare",
					"hours": "Ore",
					"minutes": "Minute",
					"seconds": "Secunde",
					"unknown": "Necunoscut",
					"amPm": [
						"AM",
						"PM"
					],
					"weekdays": [
						"lun",
						"Mar",
						"Mer",
						"Joi",
						"Vin",
						"Sam",
						"Dum"
					],
					"months": [
						"Ianuarie",
						"Februarie",
						"Martie",
						"Aprilie",
						"Mai",
						"Iunie",
						"Iulie",
						"August",
						"Septembrie",
						"Octombrie",
						"Noiembrie",
						"Decembrie"
					]
				},
				"editor": {
					"create": {
						"button": "Nou",
						"title": "Crează o intrare nouă",
						"submit": "Crează"
					},
					"edit": {
						"button": "Editează",
						"submit": "Editează",
						"title": "Editează rând"
					},
					"remove": {
						"button": "Șterge",
						"title": "Șterge",
						"submit": "Șterge",
						"confirm": {
							"_": "Sigur doriți să ștergeți %d rânduri?",
							"1": "Sigur doriți să ștergeți 1 rând?"
						}
					},
					"close": "Închide",
					"error": {
						"system": "A apărut o eroare."
					},
					"multi": {
						"title": "Valori multiple",
						"info": "Elementele selectate conțin valori diferite pentru această intrare. Pentru a edita și a seta toate elementele pentru această intrare la aceeași valoare, faceți clic sau atingeți aici, altfel își vor păstra valorile individuale.",
						"restore": "Anulează modificările",
						"noMulti": "Această intrare poate fi editată individual, dar nu face parte dintr-un grup."
					}
				},
				"decimal": ",",
				"emptyTable": "Nu există date în tabel",
				"searchPlaceholder": "Caută în tabel",
				"thousands": ".",
				"infoThousands": ".",
				"paginate": {
					"first": "Prima pagină",
					"last": "Ultima pagină",
					"next": "→",
					"previous": "←"
				}
			},
		});
		
		jQuery('.enhanced-table-simplified').DataTable({
			"order": [], // stop default sorting
			'pageLength': 25,
			"dom":  "<tr>" +
					"<'d-xl-flex flex-xl-row-reverse justify-content-between align-items-center'<p><l>>",
			initComplete: function() {

				this.api()
					.columns('.enhanced-table-column-filtrable')
					.every(function() {
						var column = this;
						var select = $('<select class="form-select"><option value=""></option></select>')
							.appendTo($(column.footer()).empty())
							.on('change', function() {
								var val = $.fn.dataTable.util.escapeRegex($(this).val());

								column.search(val ? '^' + val + '$' : '', true, false).draw();
							});

						column
							.data()
							.unique()
							.sort()
							.each(function(d, j) {
								var val = $('<div/>').html(d).text();
								select.append('<option value="' + val + '">' + val + '</option>'); // originally 'val' was 'd'
							});
					});
	
			},
			"columnDefs": [
				{
					"targets": "enhanced-table-column-not-sortable",
					"orderable": false,
				}, 
				{
					"targets": "enhanced-table-column-hidden-small-devices",
					"className": "d-none d-lg-table-cell d-xl-table-cell d-xxl-table-cell",
				}, 
				{
					"targets": "enhanced-table-column-wrap-text",
					"className": "text-wrap",
				}, 
				{
					"targets": "enhanced-table-column-width-100px",
					"width": "100px",
				}, 
			],
			language: {
				"processing": "Procesează...",
				"lengthMenu": "Afișează _MENU_ înregistrări pe pagină",
				"zeroRecords": "Nu am găsit nimic - ne pare rău",
				"info": "Afișate de la _START_ la _END_ din _TOTAL_ înregistrări",
				"infoEmpty": "Afișate de la 0 la 0 din 0 înregistrări",
				"infoFiltered": "(filtrate dintr-un total de _MAX_ înregistrări)",
				"search": "Caută:",
				"aria": {
					"sortAscending": "Sortează ascendent",
					"sortDescending": "Sortează descendent"
				},
				"autoFill": {
					"cancel": "Anulează",
					"fill": "Completează",
					"fillVertical": "Completează celule vertical",
					"fillHorizontal": "Completeză celule orizonal"
				},
				"buttons": {
					"csv": "CSV",
					"excel": "Excel",
					"pageLength": {
						"-1": "Arată toate rândurile",
						"_": "Arată %d rânduri"
					},
					"pdf": "PDF",
					"print": "Imprimă",
					"collection": "Colecție",
					"colvis": "Vizibilitate coloane",
					"copy": "Copie",
					"copyTitle": "Copie în Clipboard",
					"colvisRestore": "Resetare vizibilitate",
					"copyKeys": "Apasă ctrl sau u2318 + C pentru a copia datele din tabel în clipboard-ul sistemului.<br \/><br \/>Pentru a anula, dați clic pe acest mesaj sau apăsați pe Escape.",
					"createState": "Creează Stare",
					"removeAllStates": "Înlătură Toate Stările",
					"removeState": "Înlătură",
					"renameState": "Redenumește",
					"savedStates": "Salvează Stări"
				},
				"searchBuilder": {
					"add": "Adaugă",
					"value": "Valoare",
					"condition": "Condiție",
					"data": "Data",
					"logicAnd": "Și",
					"logicOr": "Sau",
					"button": {
						"0": "Generator de căutare",
						"_": "Generator de căutare (%d)"
					},
					"clearAll": "Curata tot",
					"conditions": {
						"date": {
							"after": "După",
							"before": "Inainte de",
							"between": "Între",
							"empty": "Gol"
						}
					},
					"deleteTitle": "Ștergeți regula de filtrare",
					"leftTitle": "Criterii Outdent",
					"rightTitle": "Criterii de indentare",
					"title": {
						"0": "Generator de căutare",
						"_": "Generator de căutare (%d)"
					}
				},
				"loadingRecords": "Încarcă...",
				"datetime": {
					"previous": "Precedentă",
					"next": "Următoare",
					"hours": "Ore",
					"minutes": "Minute",
					"seconds": "Secunde",
					"unknown": "Necunoscut",
					"amPm": [
						"AM",
						"PM"
					],
					"weekdays": [
						"lun",
						"Mar",
						"Mer",
						"Joi",
						"Vin",
						"Sam",
						"Dum"
					],
					"months": [
						"Ianuarie",
						"Februarie",
						"Martie",
						"Aprilie",
						"Mai",
						"Iunie",
						"Iulie",
						"August",
						"Septembrie",
						"Octombrie",
						"Noiembrie",
						"Decembrie"
					]
				},
				"editor": {
					"create": {
						"button": "Nou",
						"title": "Crează o intrare nouă",
						"submit": "Crează"
					},
					"edit": {
						"button": "Editează",
						"submit": "Editează",
						"title": "Editează rând"
					},
					"remove": {
						"button": "Șterge",
						"title": "Șterge",
						"submit": "Șterge",
						"confirm": {
							"_": "Sigur doriți să ștergeți %d rânduri?",
							"1": "Sigur doriți să ștergeți 1 rând?"
						}
					},
					"close": "Închide",
					"error": {
						"system": "A apărut o eroare."
					},
					"multi": {
						"title": "Valori multiple",
						"info": "Elementele selectate conțin valori diferite pentru această intrare. Pentru a edita și a seta toate elementele pentru această intrare la aceeași valoare, faceți clic sau atingeți aici, altfel își vor păstra valorile individuale.",
						"restore": "Anulează modificările",
						"noMulti": "Această intrare poate fi editată individual, dar nu face parte dintr-un grup."
					}
				},
				"decimal": ",",
				"emptyTable": "Nu există date în tabel",
				"searchPlaceholder": "Caută în tabel",
				"thousands": ".",
				"infoThousands": ".",
				"paginate": {
					"first": "Prima pagină",
					"last": "Ultima pagină",
					"next": "→",
					"previous": "←"
				}
			},
		});

	});

// Dropzone.js
// https://www.dropzone.dev/

	Dropzone.autoDiscover = false;

	// dropzone

	const dropzoneElements = document.querySelectorAll('.dropzone-standard');

	dropzoneElements.forEach(element => {

		let dz = new Dropzone(element, {
			url: "https://example.com/void.php", // Set the url for your upload script location
			paramName: "file", // The name that will be used to transfer the file
			maxFiles: 10,
			maxFilesize: 10, // MB
			addRemoveLinks: true,
		});

	});

	// dropzone queue

	const dropzoneQueueElements = document.querySelectorAll('.dropzone-queue');

	dropzoneQueueElements.forEach(element => {

		// set the preview element template
		var previewNode = element.querySelector(".dropzone-item");
		previewNode.id = "";
		var previewTemplate = previewNode.parentNode.innerHTML;
		previewNode.parentNode.removeChild(previewNode);

		let dz = new Dropzone(element, {
			url: "https://example.com/void.php", // Set the url for your upload script location
			parallelUploads: 10,
			maxFiles: 10,
			maxFilesize: 10, // Max filesize in MB
			previewTemplate: previewTemplate,
			previewsContainer: element.querySelector(".dropzone-items"), // Define the container to display the previews
			clickable: element.querySelector(".dropzone-select") // Define the element that should be used as click trigger to select files.
		});


		dz.on("addedfile", function(file) {
			// Hookup the start button
			const dropzoneItems = element.querySelectorAll('.dropzone-item');
			dropzoneItems.forEach(dropzoneItem => {
				dropzoneItem.style.display = '';
			});
		});

		// Update the total progress bar
		dz.on("totaluploadprogress", function(progress) {
			const progressBars = element.querySelectorAll('.progress-bar');
			progressBars.forEach(progressBar => {
				progressBar.style.width = progress + "%";
			});
		});

		dz.on("sending", function(file) {
			// Show the total progress bar when upload starts
			const progressBars = element.querySelectorAll('.progress-bar');
			progressBars.forEach(progressBar => {
				progressBar.style.opacity = "1";
			});
		});

		// Hide the total progress bar when nothing"s uploading anymore
		dz.on("complete", function(progress) {
			const progressBars = element.querySelectorAll('.dz-complete');

			setTimeout(function() {
				progressBars.forEach(progressBar => {
					progressBar.querySelector('.progress-bar').style.opacity = "0";
					progressBar.querySelector('.progress').style.opacity = "0";
				});
			}, 300);
		});

	});

// Date time picker
// https://getdatepicker.com/

	let datePickerElements = document.querySelectorAll('.enhanced-input-date-picker');

	datePickerElements.forEach(element => {

		let value = element.value;
		let date = moment(value, 'DD-MM-YYYY');

		new tempusDominus.TempusDominus(element, {
			display: {
				icons: {
					type:     'icons',
					time:     'icon-clock',
					date:     'icon-calendar',
					up:       'icon-arrow-up',
					down:     'icon-arrow-down',
					previous: 'icon-chevron-left',
					next:     'icon-chevron-right',
					today:    'icon-globe',
					clear:    'icon-trash',
					close:    'icon-x'
				},
				components: {
					decades: true,
					year: true,
					month: true,
					date: true,
					hours: false,
					minutes: false,
					seconds: false
				},
			},
			localization: {
				locale: "ro",
				startOfTheWeek: 1,
				format: "dd-MM-yyyy"
			},
			useCurrent: true,
			defaultDate: value ? date.format('DD-MM-YYYY') : (new Date())
		});

	});
	
// Time picker
// https://getdatepicker.com/

	let timePickerElements = document.querySelectorAll('.enhanced-input-time-picker');

	timePickerElements.forEach(element => {

		let value = element.value;
		let time = moment(value, 'HH:mm');

		new tempusDominus.TempusDominus(element, {
			display: {
				icons: {
					type:     'icons',
					time:     'icon-clock',
					date:     'icon-calendar',
					up:       'icon-arrow-up',
					down:     'icon-arrow-down',
					previous: 'icon-chevron-left',
					next:     'icon-chevron-right',
					today:    'icon-globe',
					clear:    'icon-trash',
					close:    'icon-x'
				},
				components: {
					decades: false,
					year: false,
					month: false,
					date: false,
					hours: true,
					minutes: true,
					seconds: false
				},
			},
			localization: {
				locale: "ro",
				startOfTheWeek: 1,
				format: "HH:mm"
			},
			useCurrent: true,
			defaultDate: value ? time.format('HH:mm') : (new Date())
		});

	});

// form validation
// https://formvalidation.io/
// https://formvalidation.io/guide/getting-started/usage/
// https://formvalidation.io/guide/plugins/bootstrap5
// https://formvalidation.io/guide/plugins/declarative/

	document.addEventListener('DOMContentLoaded', function(e) {

		const dynamicForms = document.querySelectorAll('.dynamic-form');

		dynamicForms.forEach(form => {

			FormValidation.formValidation(form, {
				plugins: {
					declarative: new FormValidation.plugins.Declarative(),
					trigger: new FormValidation.plugins.Trigger(),
					bootstrap5: new FormValidation.plugins.Bootstrap5({
						rowSelector: '.mb-3',
					}),
					autoFocus: new FormValidation.plugins.AutoFocus(),
					submitButton: new FormValidation.plugins.SubmitButton()
				},
			})
			.on( 'core.form.valid', e => {

				document.dispatchEvent( new CustomEvent( 'dynamicFormsSubmit', {
					detail: {
						FormValidation: e,
						form: form,
					},
					cancelable: true
				} ) );

			} );

		});

	});
	
// signatures
// 

	document.addEventListener( 'DOMContentLoaded', domEvent => {

		const signPads = document.querySelectorAll( '.signature-pad-wrap' );

		signPads.forEach( signPadWrap => {

			const canvas         = signPadWrap.querySelector( 'canvas.signature-pad' );
			const signPadClear   = signPadWrap.querySelector( '.sign-pad-clear' );
			const signatureInput = signPadWrap.querySelector( '.signature' );
			const signaturePad   = new SignaturePad( canvas );

			if( signatureInput.value ){

				signaturePad.fromDataURL( signatureInput.value, { width: 200, height: 100 } );

			}

			signPadClear.addEventListener( 'click', e => {

				signaturePad.clear();
				signatureInput.value = '';

			} );

			signaturePad.addEventListener( 'endStroke', () => {

				signatureInput.value = signaturePad.toDataURL( 'image/png' );

			} );

		} );

	} );	